import type { ColDef, ColGroupDef, ValueFormatterParams } from '@ag-grid-community/core';
import { ActionsCellRenderer } from '@pages/fx/FxProductsPage/CashTab/cells/ActionsCellRenderer';
import { CurrencyCellRenderer } from '@pages/fx/FxProductsPage/CashTab/cells/CurrencyCellRenderer';
import { type IntlFormatters, useIntl } from 'react-intl';
import { HelperCellHeader } from '../../components/HelperCellHeader';
import { DefaultCell } from './cells/DefaultCell';
import { InstrumentGroupCell } from './cells/InstrumentGroupCell';
import { MarginBidAskCell } from './cells/MarginBidAskCell';
import { MarginBidAskHeader } from './cells/MarginBidAskHeader';

export const useColumnDefs = (isGroupedByInstrument: boolean, isEditing = false): (ColDef | ColGroupDef)[] => {
  const { formatMessage } = useIntl();

  return isEditing
    ? getEditColumnDefs(formatMessage, isGroupedByInstrument)
    : getViewColumnDefs(formatMessage, isGroupedByInstrument);
};

const getViewColumnDefs = (formatMessage: IntlFormatters['formatMessage'], isGroupedByInstrument: boolean) => [
  {
    field: 'instrument',
    headerName: formatMessage({ id: 'fx.CashGrid.column.instrument' }),
    rowGroup: isGroupedByInstrument, // required to group rows by this field
    hide: true,
    cellRenderer: InstrumentGroupCell,
  },
  {
    field: 'venue',
    headerName: formatMessage({ id: 'fx.CashGrid.column.venue' }),
    cellRenderer: DefaultCell,
  },
  {
    field: 'clientVenueLogin',
    headerName: formatMessage({ id: 'fx.CashGrid.column.clientVenueLogin' }),
    cellRenderer: DefaultCell,
    minWidth: 173,
  },
  {
    field: 'currency',
    headerComponent: HelperCellHeader,
    headerComponentParams: {
      helperLocaleKey: 'fx.message.tooltip.currency',
    },
    cellRenderer: CurrencyCellRenderer,
  },
  {
    field: 'onshoreOffshore',
    headerName: formatMessage({ id: 'fx.CashGrid.column.onshorOffshore' }),
    cellRenderer: DefaultCell,
    minWidth: 172,
    valueFormatter: onshoreOffshoreValueFormatter,
  },
  // {
  //   field: 'amount',
  //   headerName: formatMessage({ id: 'fx.CashGrid.column.amount' }),
  //   cellRenderer: DefaultCell,
  //   minWidth: 168,
  // },
  {
    field: 'tenor',
    headerName: formatMessage({ id: 'fx.CashGrid.column.tenor' }),
    cellRenderer: DefaultCell,
  },
  {
    field: 'marginsBidAskOrTiering',
    cellRenderer: MarginBidAskCell,
    headerComponent: MarginBidAskHeader,
    minWidth: 227,
  },
];

const selectionColDef: ColDef = {
  field: 'selections',
  headerValueGetter: '',
  headerCheckboxSelection: true,
  showDisabledCheckboxes: true,
  maxWidth: 50,
  pinned: 'left',
  checkboxSelection: true,
};

const actionsColDef: ColDef = {
  field: 'actions',
  pinned: 'right',
  width: 125,
  cellRenderer: ActionsCellRenderer,
};

const getEditColumnDefs = (
  formatMessage: IntlFormatters['formatMessage'],
  isGroupedByInstrument: boolean,
): (ColDef | ColGroupDef)[] => [
  selectionColDef,
  ...getViewColumnDefs(formatMessage, isGroupedByInstrument),
  actionsColDef,
];

const onshoreOffshoreValueFormatter = ({ value, context }: ValueFormatterParams) => {
  if ('True' === value) {
    return context.formatMessage({ id: 'fx.editProfile.fx-cash-rfq-perimeter.shoreSelect.onshore' });
  } else if ('False' === value) {
    return context.formatMessage({ id: 'fx.editProfile.fx-cash-rfq-perimeter.shoreSelect.offshore' });
  }
  return value;
};
