import { LoadingButton } from '@components/LoadingButton';
import { FxDuplicateRowWarningModal } from '@components/modals/FxDuplicateRowWarningModal';
import { useAppSelector } from '@hooks/useAppSelector';
import { CurrencyPairSelect } from '@pages/fx/FxEditProfilePage/FxProfileEditor/CommonFields/CurrencyPairSelect';
import { InstrumentSelect } from '@pages/fx/FxEditProfilePage/FxProfileEditor/CommonFields/InstrumentSelect';
import VenueSelect from '@pages/fx/FxEditProfilePage/FxProfileEditor/CommonFields/VenueSelect';
import AlgoTypeSelect from '@pages/fx/FxEditProfilePage/FxProfileEditor/FxOrderEditor/AlgoTypeSelect';
import FixingSourceSelect from '@pages/fx/FxEditProfilePage/FxProfileEditor/FxOrderEditor/FixingSourceSelect';
import { InternalAndExternalMarginInputs } from '@pages/fx/FxEditProfilePage/FxProfileEditor/FxOrderEditor/InternalAndExternalMarginInputs';
import LimitOrderTypeSelect from '@pages/fx/FxEditProfilePage/FxProfileEditor/FxOrderEditor/LimitOrderTypeSelect';
import { useFxSelectAndEditProfileUrlParams } from '@pages/fx/useFxSelectAndEditProfileUrlParams';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useGetFxDimensionValuesQuery } from '@services/fx/getFXDimensionValues';
import type { FxCashValidationErrorCode } from '@services/fx/model/models';
import type { FxOrderDimensions } from '@services/fx/model/order';
import {
  isFxAlgoOrderDimensions,
  isFxFixingOrderDimensions,
  isFxLimitOrderDimensions,
} from '@services/fx/model/perimiters';
import { type ValidateGridErrorResponse, useValidateMarginGridMutation } from '@services/fx/validateMarginGrid';
import { isDefined, isNotDefined } from '@sgme/fp';
import { Button, HStack } from '@sgme/ui';
import { type EditingRow, cancelRowEditing } from '@store/fxProfileEdition/fxProfileEditionSlice';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { MarginInput } from './MarginInput';

type FxCashEditorFormProps = {
  editingRow: EditingRow;
};

export const FxOrderEditorForm = ({ editingRow }: FxCashEditorFormProps) => {
  // we manage the state of the currency type here as it triggers the display of different fields
  // I didn't want to pollute the editProfile state representation
  // which is domain logic, with conditional form state
  const dispatch = useDispatch();

  const [selectedCurrencyType, setSelectedCurrencyType] = useState('*');

  const [isDuplicateModalOpen, setIsDuplicateModalOpen] = useState(false);
  const [_validationErrorCode, setValidationErrorCode] = useState<FxCashValidationErrorCode>(null);

  const { productKey, gridDataType, profileId, perimeterKey } = useFxSelectAndEditProfileUrlParams();
  const [validateMarginGrid, { isLoading: isValidating }] = useValidateMarginGridMutation();
  const {
    data: dimensionValues,
    isLoading,
    isError,
  } = useGetFxDimensionValuesQuery({ productKey, gridDataType, perimeterKey });

  const rowByUiId = useAppSelector((state) => state.fxProfileEdition.rowByUiId);

  if (isLoading || isError) {
    return null;
  }

  if (isNotDefined(dimensionValues)) {
    return null;
  }

  const dimensions = dimensionValues.dimensions as FxOrderDimensions;

  const { currencies, groups, isPairEnabled, isSingleEnabled, isGroupEnabled, isAllEnabled } =
    dimensionValues.currencyPairDimension;

  const cancelEdition = () => {
    dispatch(cancelRowEditing());
  };

  const validateEdition = async () => {
    const allRows = [...Object.values(rowByUiId).filter((row) => row.uiRowId !== editingRow.uiRowId), editingRow];

    const response = await validateMarginGrid({
      perimeterKey,
      productKey,
      profileId,
      editedRows: allRows,
    });

    const { error } = response as { error: FetchBaseQueryError };

    if (isDefined(error) && isDefined(error.data)) {
      // @TODO
      // Use TS assert function thant cast type
      // https://www.typescriptlang.org/play/?#example/assertion-functions
      const errorCode = (error.data as ValidateGridErrorResponse).errorCode;

      if (errorCode === 'DuplicateRowError') {
        setIsDuplicateModalOpen(true);
      } else {
        setValidationErrorCode(errorCode);
      }
    }
  };

  // check that all values are filled
  const isApplyBtnDisabled = () =>
    Object.entries(editingRow.columns).some(([key, value]) => {
      switch (key) {
        case 'currencyPairDimension':
          // if currencyPairDimension starts or ends with a / that means the user has not yet finished
          // the currency pair selection
          return value === '' || value.endsWith('/') || value.startsWith('/');
        case 'liquidityProviderDimension.External.marginValue':
        case 'liquidityProviderDimension.Internal.marginValue':
          return value === '' || value === '-';
        default:
          return value === '';
      }
    });

  function handleDuplicateRowWarningModalCancel() {
    dispatch(cancelRowEditing());
    setIsDuplicateModalOpen(false);
  }

  //we rely on the order sent by the backend
  const lastRowId = Object.keys(rowByUiId).length - 1;
  const isEditingLastRow = editingRow.uiRowId === lastRowId;

  return (
    <>
      {/* key is used to recreate the whole form when switching between edition row to properly hydrate the default values */}
      <div key={editingRow.uiRowId} className={'d-flex flex-column gap-16px h-100 pt-2'}>
        <VenueSelect disabled={isEditingLastRow} mediaDimensions={dimensions.mediaDimension} />

        {isFxFixingOrderDimensions(dimensions) && (
          <FixingSourceSelect disabled={isEditingLastRow} fixingSourceDimension={dimensions.fixingSourceDimension} />
        )}

        {isFxAlgoOrderDimensions(dimensions) && (
          <AlgoTypeSelect disabled={isEditingLastRow} algoTypeDimension={dimensions.algoTypeDimension} />
        )}

        <InstrumentSelect disabled={isEditingLastRow} instrumentDimension={dimensions.instrumentDimension} />

        {isFxLimitOrderDimensions(dimensions) && (
          <LimitOrderTypeSelect
            limitOrderTypeDimension={dimensions.limitOrderTypeDimension}
            disabled={isEditingLastRow}
          />
        )}

        <CurrencyPairSelect
          selectedCurrencyType={selectedCurrencyType}
          setSelectedCurrencyType={setSelectedCurrencyType}
          currencies={currencies}
          groups={groups}
          disabled={isEditingLastRow}
          isPairEnabled={isPairEnabled}
          isSingleEnabled={isSingleEnabled}
          isGroupEnabled={isGroupEnabled}
          isAllEnabled={isAllEnabled}
          isOrder={true}
        />

        {isFxAlgoOrderDimensions(dimensions) && <InternalAndExternalMarginInputs />}

        {(productKey === 'fixing-order-product' || productKey === 'limit-order-product') && <MarginInput />}

        <HStack gap="16px">
          <Button className="btn-lg w-50" onClick={cancelEdition}>
            <FormattedMessage id={'common.cancel'} />
          </Button>
          <LoadingButton
            disabled={isApplyBtnDisabled()}
            loading={isValidating}
            type="submit"
            className="btn btn-info btn-lg w-50"
            data-e2e="apply-margin-setup"
            onClick={validateEdition}
          >
            <FormattedMessage id={'common.apply'} />
          </LoadingButton>
        </HStack>
      </div>

      <FxDuplicateRowWarningModal
        isOpen={isDuplicateModalOpen}
        onCancel={handleDuplicateRowWarningModalCancel}
        closeForm={() => setIsDuplicateModalOpen(false)}
      />
    </>
  );
};
