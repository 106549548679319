import type { Components, SgPickerCustomEvent } from '@sg-bootstrap/components';
import { SgPicker as SgPickerComponent } from '@sg-bootstrap/components/dist/react-library/src/components';
import { isDefined } from '@sgme/fp';
import type { RefObject } from 'react';
import { useCallback, useEffect, useRef } from 'react';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'sg-picker': Partial<Components.SgPicker> & { ref: RefObject<HTMLSgPickerElement> };
    }
  }
}

export interface Item {
  label: string;
  key: string;
}

interface SgPickerProps<T> {
  className?: string;
  id?: string;
  selectFirstByDefault?: boolean;
  defaultValue?: Item['key'];
  noClear?: boolean;
  items: string[] | Item[];
  placeholder?: string;
  mode: Components.SgPicker['mode'];
  state?: Components.SgPicker['state'];
  singleSelect?: boolean;
  noIcon?: boolean;
  disabled?: boolean;
  onChange?: (detail: T) => void;
}

export const SgPicker = <T extends Item>({
  className = '',
  id = '',
  selectFirstByDefault = false,
  defaultValue,
  noClear = false,
  items,
  placeholder,
  mode,
  singleSelect,
  noIcon,
  state,
  disabled = false,
  onChange,
}: SgPickerProps<T>): JSX.Element => {
  const innerRef = useRef<HTMLSgPickerElement>(null);

  const innerOnReady = useCallback(() => {
    const formattedItems = items.map(transformItem);

    innerRef.current?.setItems(formattedItems);

    if (isDefined(defaultValue)) {
      innerRef.current?.selectItemByKey(defaultValue);
      return;
    }

    if (selectFirstByDefault) {
      const [firstItem] = formattedItems;

      innerRef.current?.selectItemByKey(firstItem.key);

      if (onChange) {
        onChange(firstItem as T);
      }
    }
  }, [defaultValue, items, onChange, selectFirstByDefault]);

  const onSelectItem = useCallback(
    (event: SgPickerCustomEvent<T>) => {
      if (onChange) {
        onChange(event.detail);
      }
    },
    [onChange],
  );

  useEffect(() => {
    const picker = innerRef.current;

    if (picker) {
      picker.addEventListener('selectItem', onSelectItem as EventListener);
      picker.addEventListener('unselectItem', onSelectItem as EventListener);
      picker.addEventListener('clear', onSelectItem as EventListener);

      picker.addEventListener('ready', innerOnReady);
    }

    return () => {
      if (picker) {
        picker.removeEventListener('selectItem', onSelectItem as EventListener);
        picker.removeEventListener('unselectItem', onSelectItem as EventListener);
        picker.removeEventListener('clear', onSelectItem as EventListener);
        picker.removeEventListener('ready', innerOnReady);
      }
    };
  }, [onSelectItem, innerOnReady]);

  return (
    <SgPickerComponent
      ref={innerRef}
      classes={className}
      input-id={id}
      hide-no-result-message
      placeholder={placeholder}
      mode={mode}
      single-select={singleSelect}
      no-icon={noIcon}
      no-clear={noClear}
      state={state}
      disabled={disabled}
    />
  );
};

const transformItem = (item: string | Item) => {
  if (typeof item === 'string') {
    return { label: item, key: item };
  }

  return item;
};
