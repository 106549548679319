import type { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { type Item, SgPicker } from '@components/SgPicker';
import { mapAndTranslateOptions } from './utils';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { useAppSelector } from '@hooks/useAppSelector';
import { HelpCenter } from '@components/HelpCenter';
import { changeColumnMediaDimension } from '@store/fxProfileEdition/fxProfileEditionSlice';

type VenueSelectProps = {
  mediaDimensions: Array<string>;
  disabled: boolean;
};
const VenueSelect: FC<VenueSelectProps> = ({ mediaDimensions, disabled }) => {
  const { formatMessage } = useIntl();

  const dispatch = useAppDispatch();

  const venueValue = useAppSelector((state) => state.fxProfileEdition.editingRow?.columns.mediaDimension);

  const setVenueValue = (item: Item | null) => {
    dispatch(changeColumnMediaDimension({ key: 'mediaDimension', value: item?.key ?? '' }));
  };

  return (
    <div className={`form-group`} data-e2e="media-dimension-select">
      <div className={'d-flex flex-between'}>
        <label className="text-primary fw-medium" htmlFor="mediaDimensionSelect">
          <FormattedMessage id="mediaDimension" />
        </label>
        <div>
          <HelpCenter className={'text-secondary'} withHandler />
        </div>
      </div>

      <SgPicker
        id="mediaDimensionSelect"
        defaultValue={venueValue}
        onChange={setVenueValue}
        mode="filter"
        singleSelect
        noIcon
        noClear
        disabled={disabled}
        items={mapAndTranslateOptions(formatMessage, mediaDimensions)}
      />
    </div>
  );
};

export default VenueSelect;
