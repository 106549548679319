import type { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { type Item, SgPicker } from '@components/SgPicker';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { useAppSelector } from '@hooks/useAppSelector';
import { changeFixingOrderColumn } from '@store/fxProfileEdition/fxProfileEditionSlice';
import { HelpCenter } from '@components/HelpCenter';
import type { FxOrderProfileFixingColumns } from '@services/fx/model/order';
import { mapAndTranslateOptions } from '@pages/fx/FxEditProfilePage/FxProfileEditor/CommonFields/utils';

type FixingSourceSelectProps = {
  fixingSourceDimension: Array<string>;
  disabled: boolean;
};
const FixingSourceSelect: FC<FixingSourceSelectProps> = ({ fixingSourceDimension, disabled }) => {
  const { formatMessage } = useIntl();

  const dispatch = useAppDispatch();

  const fixingSourceValue = useAppSelector(
    (state) => (state.fxProfileEdition.editingRow?.columns as FxOrderProfileFixingColumns).fixingSourceDimension,
  );

  const setFixingSourceValue = (item: Item | null) => {
    dispatch(changeFixingOrderColumn({ value: item?.key ?? '', key: 'fixingSourceDimension' }));
  };

  return (
    <div className={`form-group`} data-e2e="fixing-source-select">
      <div className={'d-flex flex-between'}>
        <label className="text-primary fw-medium" htmlFor="fixingSource">
          <FormattedMessage id="fixingSourceDimension" />
        </label>
        <div>
          <HelpCenter className={'text-secondary'} withHandler />
        </div>
      </div>

      <SgPicker
        id="fixingSourceSelect"
        defaultValue={fixingSourceValue}
        onChange={setFixingSourceValue}
        mode="filter"
        singleSelect
        noIcon
        noClear
        disabled={disabled}
        items={mapAndTranslateOptions(formatMessage, fixingSourceDimension)}
      />
    </div>
  );
};

export default FixingSourceSelect;
