import { LoadingButton } from '@components/LoadingButton';
import { FxDuplicateRowWarningModal } from '@components/modals/FxDuplicateRowWarningModal';
import { useAppSelector } from '@hooks/useAppSelector';
import ClientVenueLoginSelect from '@pages/fx/FxEditProfilePage/FxProfileEditor/CommonFields/ClientVenueLoginSelect';
import { CurrencyPairSelect } from '@pages/fx/FxEditProfilePage/FxProfileEditor/CommonFields/CurrencyPairSelect';
import SetupSwitch from '@pages/fx/FxEditProfilePage/FxProfileEditor/CommonFields/SetupSwitch';
import ShoreSelect from '@pages/fx/FxEditProfilePage/FxProfileEditor/CommonFields/ShoreSelect';
import TenorSelect from '@pages/fx/FxEditProfilePage/FxProfileEditor/CommonFields/TenorSelect';
import VenueSelect from '@pages/fx/FxEditProfilePage/FxProfileEditor/CommonFields/VenueSelect';
import MarginUnitsSelectAndInputs from '@pages/fx/FxEditProfilePage/FxProfileEditor/FxCashMarginEditor/MarginUnitsSelectAndInputs';
import TieringSelect from '@pages/fx/FxEditProfilePage/FxProfileEditor/FxCashTieringEditor/TieringSelect';
import { useFxSelectAndEditProfileUrlParams } from '@pages/fx/useFxSelectAndEditProfileUrlParams';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useGetFxDimensionValuesQuery } from '@services/fx/getFXDimensionValues';
import type { FxCashDimensions, FxCashProductKey, FxCashTieringDimensionsValues } from '@services/fx/model/cash';
import type { FxCashValidationErrorCode } from '@services/fx/model/models';
import {
  isFxCashProfileMarginColumns,
  isFxCashProfileRejectedColumns,
  isFxCashProfileTieringColumns,
} from '@services/fx/model/perimiters';
import { useValidateMarginGridMutation, type ValidateGridErrorResponse } from '@services/fx/validateMarginGrid';
import { isDefined, isNotDefined } from '@sgme/fp';
import { Button, HStack } from '@sgme/ui';
import { cancelRowEditing, type EditingRow } from '@store/fxProfileEdition/fxProfileEditionSlice';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

type FxCashEditorFormProps = {
  editingRow: EditingRow;
};

export const FxCashEditorForm = ({ editingRow }: FxCashEditorFormProps) => {
  // we manage the state of the currency type here as it triggers the display of different fields
  // I didn't want to pollute the editProfile state representation
  // which is domain logic, with conditional form state
  const [selectedCurrencyType, setSelectedCurrencyType] = useState('*');
  const [isDuplicateModalOpen, setIsDuplicateModalOpen] = useState(false);
  const [validationErrorCode, setValidationErrorCode] = useState<FxCashValidationErrorCode>(null);
  const dispatch = useDispatch();

  const { productKey, gridDataType, profileId, perimeterKey } = useFxSelectAndEditProfileUrlParams();

  const [validateMarginGrid, { isLoading: isValidating }] = useValidateMarginGridMutation();
  const {
    data: dimensionValues,
    isLoading,
    isError,
  } = useGetFxDimensionValuesQuery({ productKey, gridDataType, perimeterKey });

  const rowByUiId = useAppSelector((state) => state.fxProfileEdition.rowByUiId);

  const cancelEdition = () => {
    dispatch(cancelRowEditing());
  };

  if (isLoading || isError) {
    return null;
  }

  if (isNotDefined(dimensionValues)) {
    return null;
  }

  const isEditingRowMargin = isFxCashProfileMarginColumns(editingRow.columns);
  const isEditingRowTiering = isFxCashProfileTieringColumns(editingRow.columns);
  const dimensions = dimensionValues.dimensions as FxCashDimensions;
  const { currencies, groups, isPairEnabled, isSingleEnabled, isGroupEnabled, isAllEnabled } =
    dimensionValues.currencyPairDimension;

  const validateEdition = async () => {
    const allRows = [...Object.values(rowByUiId).filter((row) => row.uiRowId !== editingRow.uiRowId), editingRow];

    const response = await validateMarginGrid({
      perimeterKey,
      productKey,
      gridDataType,
      profileId,
      editedRows: allRows,
    });

    const { error } = response as { error: FetchBaseQueryError };

    if (isDefined(error) && isDefined(error.data)) {
      // @TODO
      // Use TS assert function thant cast type
      // https://www.typescriptlang.org/play/?#example/assertion-functions
      const errorCode = (error.data as ValidateGridErrorResponse).errorCode;

      if (errorCode === 'DuplicateRowError') {
        setIsDuplicateModalOpen(true);
      } else {
        setValidationErrorCode(errorCode);
      }
    }
  };

  // check that all values are filled
  const isApplyBtnDisabled = () =>
    Object.entries(editingRow.columns).some(([key, value]) => {
      switch (key) {
        case 'currencyPairDimension':
          // if currencyPairDimension starts or ends with a / that means the user has not yet finished
          // the currency pair selection
          return value === '' || value.endsWith('/') || value.startsWith('/');
        case 'tieringResult': {
          // in case of rejection the backend still provides a tieringResult with empty string as value,
          // but this should not be taken into account in the disabling logic as
          // this is not relevant for the user
          if (isFxCashProfileRejectedColumns(editingRow.columns)) {
            return false;
          }
          return value === '';
        }
        case 'marginBidValue':
        case 'marginAskValue':
          return value === '' || value === '-';
        default:
          return value === '';
      }
    });

  //we rely on the order sent by the backend
  const lastRowId = Object.keys(rowByUiId).length - 1;
  const isEditingLastRow = editingRow.uiRowId === lastRowId;

  function handleDuplicateRowWarningModalCancel() {
    cancelEdition();
    setIsDuplicateModalOpen(false);
  }

  return (
    <>
      {/* key is used to recreate the whole form when switching between edition row to properly hydrate the default values */}
      <div key={editingRow.uiRowId} className={'d-flex flex-column gap-16px h-100 pt-2'}>
        <SetupSwitch
          gridDataType={gridDataType!}
          productKey={productKey as FxCashProductKey}
          isEditingRowMargin={isEditingRowMargin}
          isEditingRowTiering={isEditingRowTiering}
        />

        <VenueSelect disabled={isEditingLastRow} mediaDimensions={dimensions.mediaDimension} />

        <ClientVenueLoginSelect disabled={isEditingLastRow} />

        <CurrencyPairSelect
          selectedCurrencyType={selectedCurrencyType}
          setSelectedCurrencyType={setSelectedCurrencyType}
          currencies={currencies}
          groups={groups}
          disabled={isEditingLastRow}
          isPairEnabled={isPairEnabled}
          isSingleEnabled={isSingleEnabled}
          isGroupEnabled={isGroupEnabled}
          isAllEnabled={isAllEnabled}
          isOrder={false}
        />

        {selectedCurrencyType === 'pair' && <ShoreSelect />}

        {/* <AmountInput disabled={isEditingLastRow} validationErrorCode={validationErrorCode} /> */}

        <TenorSelect
          dimensions={dimensions.tenorDimension}
          disabled={isEditingLastRow}
          validationErrorCode={validationErrorCode}
        />

        {isEditingRowTiering && (
          <TieringSelect tieringDimension={(dimensionValues as FxCashTieringDimensionsValues).tiering} />
        )}

        {isEditingRowMargin && (
          <>
            <div className={'border-bottom'} />
            <MarginUnitsSelectAndInputs disabled={isEditingLastRow} selectedCurrencyType={selectedCurrencyType} />
          </>
        )}
        <HStack gap="16px">
          <Button className="btn-lg w-50" onClick={cancelEdition}>
            <FormattedMessage id={'common.cancel'} />
          </Button>
          <LoadingButton
            disabled={isApplyBtnDisabled()}
            loading={isValidating}
            type="submit"
            className="btn btn-info btn-lg w-50"
            data-e2e="apply-margin-setup"
            onClick={validateEdition}
          >
            <FormattedMessage id={'common.apply'} />
          </LoadingButton>
        </HStack>
      </div>
      <FxDuplicateRowWarningModal
        isOpen={isDuplicateModalOpen}
        onCancel={handleDuplicateRowWarningModalCancel}
        closeForm={() => setIsDuplicateModalOpen(false)}
      ></FxDuplicateRowWarningModal>
    </>
  );
};
