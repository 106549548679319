import type { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { type Item, SgPicker } from '@components/SgPicker';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { useAppSelector } from '@hooks/useAppSelector';
import { changeAlgoOrderColumn } from '@store/fxProfileEdition/fxProfileEditionSlice';
import { HelpCenter } from '@components/HelpCenter';
import { mapAndTranslateOptions } from '@pages/fx/FxEditProfilePage/FxProfileEditor/CommonFields/utils';
import type { FxOrderProfileAlgoColumns } from '@services/fx/model/order';

type AlgoTypeSelectProps = {
  algoTypeDimension: Array<string>;
  disabled: boolean;
};
const AlgoTypeSelect: FC<AlgoTypeSelectProps> = ({ algoTypeDimension, disabled }) => {
  const { formatMessage } = useIntl();

  const dispatch = useAppDispatch();

  const algoTypeValue = useAppSelector(
    (state) => (state.fxProfileEdition.editingRow?.columns as FxOrderProfileAlgoColumns).algoTypeDimension,
  );

  const setAlgoTypeValue = (item: Item | null) => {
    dispatch(changeAlgoOrderColumn({ value: item?.key ?? '', key: 'algoTypeDimension' }));
  };

  return (
    <div className={`form-group`} data-e2e="algo-type-select">
      <div className={'d-flex flex-between'}>
        <label className="text-primary fw-medium" htmlFor="algoType">
          <FormattedMessage id="algoTypeDimension" />
        </label>
        <div>
          <HelpCenter className={'text-secondary'} withHandler />
        </div>
      </div>

      <SgPicker
        id="algoTypeSelect"
        defaultValue={algoTypeValue}
        onChange={setAlgoTypeValue}
        mode="filter"
        singleSelect
        noIcon
        noClear
        disabled={disabled}
        items={mapAndTranslateOptions(formatMessage, algoTypeDimension)}
      />
    </div>
  );
};

export default AlgoTypeSelect;
