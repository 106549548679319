import { Button, ButtonGroup } from "reactstrap";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { useAppSelector } from "@hooks/useAppSelector";
import type { FxCashProfileMarginColumns } from "@services/fx/model/cash";
import {
  changeCashColumnDimension,
  changeMarginColumnValue,
} from "@store/fxProfileEdition/fxProfileEditionSlice";
import { useEffect, type ChangeEvent, type FC } from "react";
import { HStack } from "@sgme/ui";
import { HelpCenter } from "@components/HelpCenter";
import { MarginInputText } from "@pages/fx/FxEditProfilePage/FxProfileEditor/FxOrderEditor/MarginInputText";

type MarginUnitTypesSelectProps = {
  selectedCurrencyType: string;
  disabled: boolean;
};

const MarginUnitsSelectAndInputs: FC<MarginUnitTypesSelectProps> = ({
  selectedCurrencyType,
}) => {
  const dispatch = useDispatch();

  const marginUnitValue = useAppSelector(
    (state) =>
      (state.fxProfileEdition.editingRow?.columns as FxCashProfileMarginColumns)
        .marginUnit,
  ) as "mln" | "bps" | "pips";
  const applyInvertedPairValue = useAppSelector(
    (state) =>
      (state.fxProfileEdition.editingRow?.columns as FxCashProfileMarginColumns)
        .applyOnInvertedPairDimension,
  );
  const marginBidValue = useAppSelector(
    (state) =>
      (state.fxProfileEdition.editingRow?.columns as FxCashProfileMarginColumns)
        .marginBidValue,
  );
  const marginAskValue = useAppSelector(
    (state) =>
      (state.fxProfileEdition.editingRow?.columns as FxCashProfileMarginColumns)
        .marginAskValue,
  );
  const isNewRow = useAppSelector(
    (state) => state.fxProfileEdition.editingRow?.state === "added",
  );

  useEffect(() => {
    const shouldTickApplyOnInvertedPairDimension =
      (isNewRow && selectedCurrencyType === "pair") || applyInvertedPairValue === 'True';

    shouldTickApplyOnInvertedPairDimension
      ? dispatch(
        changeCashColumnDimension({
          key: "applyOnInvertedPairDimension",
          value: "True",
        }),
      )
      : dispatch(
        changeCashColumnDimension({
          key: "applyOnInvertedPairDimension",
          value: "False",
        }),
      );
  }, [isNewRow, selectedCurrencyType, applyInvertedPairValue, dispatch]);

  const onClickPips = () => {
    dispatch(changeMarginColumnValue({ key: "marginUnit", value: "pips" }));
    dispatch(
      changeCashColumnDimension({
        key: "applyOnInvertedPairDimension",
        value: "False",
      }),
    );
  };
  const onClickBps = () =>
    dispatch(changeMarginColumnValue({ key: "marginUnit", value: "bps" }));

  const onChangeApplyInvertedPairValue = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    //expected backend format
    const value = event.target.checked ? "True" : "False";
    dispatch(
      changeCashColumnDimension({ key: "applyOnInvertedPairDimension", value }),
    );
  };

  const onChangeMarginBidValue = (value: string) =>
    dispatch(changeMarginColumnValue({ key: "marginBidValue", value }));

  const onChangeMarginAskValue = (value: string) =>
    dispatch(changeMarginColumnValue({ key: "marginAskValue", value }));

  const isPipsDisabled =
    selectedCurrencyType === "group" || selectedCurrencyType === "single";
  const displayInvertedCurrencyCheckBox = selectedCurrencyType === "pair";

  const checked = isNewRow
    ? isNewRow && applyInvertedPairValue === "True"
    : applyInvertedPairValue === "True";

  return (
    <>
      <ButtonGroup id={"marginUnitType"} className="w-100">
        <Button
          className="btn-toggle-primary w-50"
          color="primary"
          outline
          onClick={onClickBps}
          active={marginUnitValue === "bps"}
          data-e2e="margin-unit-bps"
        >
          <FormattedMessage id={`common.unit.bps`} />
        </Button>

        <Button
          className="btn-toggle-primary w-50"
          color="primary"
          outline
          disabled={isPipsDisabled}
          onClick={onClickPips}
          data-e2e="margin-unit-pips"
          active={marginUnitValue === "pips"}
        >
          <FormattedMessage id={`common.unit.pips`} />
        </Button>
      </ButtonGroup>

      {displayInvertedCurrencyCheckBox && (
        <div className={`form-check`}>
          <input
            type="checkbox"
            id={"applyOnInvertedPairCurrency"}
            data-e2e="apply-on-inverted-pair-currency-checkbox"
            disabled={marginUnitValue === "pips"}
            className="form-check-input"
            checked={checked}
            onChange={onChangeApplyInvertedPairValue}
          />
          <label
            className="form-check-label text-primary"
            htmlFor={"applyOnInvertedPairCurrency"}
          >
            <FormattedMessage
              id={
                "fx.editProfile.fx-cash-rfq-perimeter.margin.applyOnInvertedPairCurrency.label"
              }
            />
          </label>
          <div>
            <HelpCenter className={"text-secondary"} withHandler />
          </div>
        </div>
      )}

      <HStack gap={"8px"}>
        <MarginInputText
          labelLocalKey="fx.editProfile.fx-cash-rfq-perimeter.marginBidValue"
          name="bid"
          value={marginBidValue}
          unit={marginUnitValue}
          onChange={onChangeMarginBidValue}
        />
        <MarginInputText
          labelLocalKey="fx.editProfile.fx-cash-rfq-perimeter.marginAskValue"
          name="ask"
          value={marginAskValue}
          unit={marginUnitValue}
          onChange={onChangeMarginAskValue}
        />
      </HStack>
    </>
  );
};

export default MarginUnitsSelectAndInputs;
