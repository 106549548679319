import { FormattedMessage } from 'react-intl';
import { ModalHandler } from 'components/modals/ModalHandler';
import { Authorize } from '@features/authorization/Authorize';
import type { ProfileId, Profiles } from '@services/fx/model/models';
import type { EditPermission } from '@features/authorization/authorization.model';
import type { FxPerimeterKey } from '@services/fx/model/perimiters';

type DefaultProfilPropsType = {
  height: number;
  availableProfilesOfTypeFiltered: Profiles[];
  radioGroup?: string;
  editedProfileId: number | undefined;
  isEditedProfileInherited: boolean | undefined;
  profileTypeKey: string;
  onChange: (profileId: ProfileId) => void;
  authorizedPermissions: EditPermission[];
  perimeterKey: FxPerimeterKey;
};

const DefaultProfileType = (props: DefaultProfilPropsType) => {
  const {
    height,
    availableProfilesOfTypeFiltered,
    radioGroup,
    editedProfileId,
    isEditedProfileInherited,
    profileTypeKey,
    onChange,
    authorizedPermissions,
    perimeterKey,
  } = props;

  return (
    <>
      <ul className="list-group border overflow-auto" style={{ maxHeight: `${height}em` }}>
        {availableProfilesOfTypeFiltered.map(({ profileId, profileName, defaultProfile }) => (
          <ProfileItem
            key={profileId}
            radioGroup={radioGroup}
            profileId={profileId}
            profileName={profileName}
            isDefaultProfile={defaultProfile}
            editedProfileId={editedProfileId}
            isEditedProfileInherited={isEditedProfileInherited}
            onChange={onChange}
          />
        ))}

        {availableProfilesOfTypeFiltered.length === 0 && (
          <label className="list-group-item list-group-item-primary p-2 border-start-0 text-light fw-medium mb-2">
            <FormattedMessage id="no.preset.found" />
          </label>
        )}
      </ul>

      {profileTypeKey === 'client-pool-profile-type' && (
        <Authorize authorizedFor={authorizedPermissions}>
          <ModalHandler
            modalName={perimeterKey === 'fx-order-perimeter' ? 'ClientPoolPresetOrderFormModal' : 'ClientPoolPresetCashFormModal'}
          >
            <button
              className="form-control mt-1 btn btn-md btn-outline-primary align-self-center"
              data-e2e="add-new-client-preset-pool"
            >
              <FormattedMessage id="button.newPreset.clientPool" />
            </button>
          </ModalHandler>
        </Authorize>
      )}

      {profileTypeKey === 'standard-profile-type' && (
        <Authorize authorizedFor={authorizedPermissions}>
          <ModalHandler
            modalName={perimeterKey === 'fx-order-perimeter' ? 'StandardPresetOrderFormModal' : 'StandardPresetCashFormModal'}
          >
            <button
              className="form-control mt-1 btn btn-md btn-outline-primary align-self-center"
              data-e2e="add-new-standard-preset"
            >
              <FormattedMessage id="button.newPreset.Standard" />
            </button>
          </ModalHandler>
        </Authorize>
      )}
    </>
  );
};

export default DefaultProfileType;

// ██████╗ ██████╗  ██████╗ ███████╗██╗██╗     ███████╗    ██╗████████╗███████╗███╗   ███╗
// ██╔══██╗██╔══██╗██╔═══██╗██╔════╝██║██║     ██╔════╝    ██║╚══██╔══╝██╔════╝████╗ ████║
// ██████╔╝██████╔╝██║   ██║█████╗  ██║██║     █████╗      ██║   ██║   █████╗  ██╔████╔██║
// ██╔═══╝ ██╔══██╗██║   ██║██╔══╝  ██║██║     ██╔══╝      ██║   ██║   ██╔══╝  ██║╚██╔╝██║
// ██║     ██║  ██║╚██████╔╝██║     ██║███████╗███████╗    ██║   ██║   ███████╗██║ ╚═╝ ██║
// ╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═╝     ╚═╝╚══════╝╚══════╝    ╚═╝   ╚═╝   ╚══════╝╚═╝     ╚═╝

type ProfileItemProps = {
  radioGroup?: string;
  profileId: number;
  profileName: string;
  isDefaultProfile: boolean;
  editedProfileId: number | undefined;
  isEditedProfileInherited: boolean | undefined;
  onChange: (profileId: ProfileId) => void;
};

const ProfileItem = (props: ProfileItemProps) => {
  const { radioGroup, profileId, profileName, isDefaultProfile, editedProfileId, isEditedProfileInherited, onChange } =
    props;

  const isSelected = profileId === editedProfileId && !isEditedProfileInherited;

  const onSelect = () => {
    onChange(profileId);
  };

  return (
    <label
      key={profileId}
      htmlFor={`${profileId}`}
      // onClick={onSelect}
      className={`list-group-item list-group-item-action list-group-item-primary d-flex align-items-center justify-content-between border-bottom p-2 border-start-0 fw-medium ${isDefaultProfile ? 'text-info' : ''
        }`}
    >
      {profileName}

      <input
        type="radio"
        id={`${profileId}`}
        value={profileId}
        name={radioGroup}
        checked={isSelected}
        onChange={onSelect}
      />
    </label>
  );
};
