import type { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { type Item, SgPicker } from '@components/SgPicker';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { useAppSelector } from '@hooks/useAppSelector';
import { changeLimitOrderColumn } from '@store/fxProfileEdition/fxProfileEditionSlice';
import { HelpCenter } from '@components/HelpCenter';
import type { FxOrderProfileLimitColumns } from '@services/fx/model/order';
import { mapAndTranslateOptions } from '@pages/fx/FxEditProfilePage/FxProfileEditor/CommonFields/utils';

type LimitOrderTypeSelectProps = {
  limitOrderTypeDimension: Array<string>;
  disabled: boolean;
};
const LimitOrderTypeSelect: FC<LimitOrderTypeSelectProps> = ({ limitOrderTypeDimension, disabled }) => {
  const { formatMessage } = useIntl();

  const dispatch = useAppDispatch();

  const limitTypeValue = useAppSelector(
    (state) => (state.fxProfileEdition.editingRow?.columns as FxOrderProfileLimitColumns).limitOrderTypeDimension,
  );

  const setLimitTypeValue = (item: Item | null) => {
    dispatch(changeLimitOrderColumn({ value: item?.key ?? '', key: 'limitOrderTypeDimension' }));
  };

  return (
    <div className={`form-group`} data-e2e="limit-order-type-select">
      <div className={'d-flex flex-between'}>
        <label className="text-primary fw-medium" htmlFor="limitOrderType">
          <FormattedMessage id="limitOrderTypeDimension" />
        </label>
        <div>
          <HelpCenter className={'text-secondary'} withHandler />
        </div>
      </div>

      <SgPicker
        id="limitOrderTypeSelect"
        defaultValue={limitTypeValue}
        onChange={setLimitTypeValue}
        mode="filter"
        singleSelect
        noIcon
        noClear
        disabled={disabled}
        items={mapAndTranslateOptions(formatMessage, limitOrderTypeDimension)}
      />
    </div>
  );
};

export default LimitOrderTypeSelect;
